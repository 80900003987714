<template>
  <div class="" id="content" :class="`template--${template} ${template=='BACKGROUND_IMAGE'?'background-container':''}`" :style="{backgroundImage: template=='BACKGROUND_IMAGE' && this.page !== null && this.page.image ? `url('${page.image}')` : null}">
    
    <div v-if="(template == 'default' || template == 'INDEX') && (page || menuItem)" id="page-hero" class="container hero-container mb-n8 px-0 pt-6 pt-md-12 pb-8 white--text container--fluid">
      <div class="container">
        <!-- <h1 class="display-2 mt-16">{{ (page && page.title) || (menuItem && menuItem.title) }}</h1> -->
      </div>
    </div>
    <div v-if="template == 'HERO_IMAGE' && (page || menuItem)" id="page-hero" class="container hero-container mb-n8 px-0 pt-6 pt-md-12 pb-8 white--text container--fluid" :style="{backgroundImage: template=='HERO_IMAGE' && this.page !== null && this.page.image ? `url('${page.image}')` : null}">
      <div class="container">
        <!-- <h1 class="display-2 mt-16">{{ (page && page.title) || (menuItem && menuItem.title) }}</h1> -->
      </div>
    </div>

    <v-container v-if="page && template != 'INDEX'">
      <v-card>
        <v-breadcrumbs :items="breadcrumbs" class="px-4" />
        <div :class="`px-4 ${$vuetify.breakpoint.xs?'':''}`">
          <h1 v-if="menuItem && menuItem.title">{{ menuItem.title }}</h1>
          <h1 v-else>{{ page.title }}</h1>
        </div>

        <vue-markdown v-if="page.content" class="px-4 markdown dynamic-content" :html="false" :source="page.content" />

        <div v-if="page.post_content_section === 'DEVICES'" class="px-4 mt-8">  
          <h2 class="subheader mt-8">Integration with all major fitness trackers</h2>
          <p>
            We offer native integration with all major fitness wearables for automatic upload of activities. This greatly improves the experience of your participants! <br/>
            Our manual entry tool allows quick entry for everyone without a connected GPS device.
          </p>
          <SectionIntegrations />
        </div>
        <div v-if="page.post_content_section === 'CUSTOMERS'" class="mt-8">  
          <div id="page-customers" class="blob-bg white--text">
            <div class="container pb-0">
              <v-row>
                <v-col class="pb-4">
                  <h1 class="headline">Trusted by global corporations</h1>
                  <p>
                    {{tenant.name}} is used by world-renowned corporations to host their connected fitness events. Some of our key partners are:
                  </p>
                  <SectionCustomers :showcase="false" corporate/>

                </v-col>
              </v-row>
            </div>
          </div>
        </div>

        <div v-if="!page.hide_contact_banner" class="green white--text py-2 py-md-6 mt-8"> 
          <div class="container text-center">
            <v-row>
              <v-col cols="12" md="12">
                <h1 class="subtitle">Get started today!</h1>
                <p class="mb-0 mt-4">
                  <v-btn class="mb-4" color="white"  href="/contact">Contact Us</v-btn>
                  <v-btn class="ms-4 mb-4" color="white" outlined :to="{name:'startTrial'}">Start Free Trial</v-btn>
                </p>
              </v-col>
            </v-row>
          </div>
        </div>

        <div v-if="page.page_links && page.page_links.length" class="mt-0">  
          <!-- <v-divider class="mb-4"/> -->
          <h3 class="px-4 mt-4">Read more</h3>
          <v-row class="mx-2">
            <v-col cols="12" sm="6" md="4" lg="3" v-for="(item, i) in page.page_links" :key="i" class="d-flex flex-column">
              <big-button 
                :title="item.title" 
                :text="item.summary"
                :to="getPagePath(item.slug)" 
                :href="isAbsoluteUrl(item.path) ? item.path : null"
                :icon="item.icon" 
                elevated
                class="flex d-flex flex-column" 
                />
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-container>

    <v-container v-else-if="menuItem">
      <v-card class="px-4">
      <v-breadcrumbs :items="breadcrumbs" />
      <div :class="` ${$vuetify.breakpoint.xs?'':''}`">
        <h1>{{ menuItem.title }}</h1>
      </div>

      <vue-markdown v-if="page && page.content" class="markdown dynamic-content" :html="false" :source="page.content" />

      <div class="white--text">
        <div class="containerZ">
          <v-row>
            <v-col v-for="(item,idx) in menuItem.items" :key="idx"  cols="12" sm="6" class="d-flex flex-column">
              <v-card :color="$helpers.getGradientColorAt(tenant.theme.header, tenant.theme.primary, idx / (menuItem.items.length-1))" :to="isAbsoluteUrl(item.path) ? null : item.path" :href="isAbsoluteUrl(item.path) ? item.path : null" class="flex d-flex flex-column">
                <!-- <v-card-text class="pb-0"><v-icon size="100">fadl fal fa-flag-checkered</v-icon></v-card-text> -->
                <v-card-title class="headline">{{item.title}}</v-card-title>
                <v-card-text class="flex">
                  {{ item.related && item.related.summary }}
                </v-card-text>
                <v-card-actions>
                  <v-btn text :to="item.path">Read More</v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </div>
        
      </v-card>
    </v-container>
  </div>

</template>

<script>

import VueMarkdown from '@/components/VueMarkdown.vue'
import cmsService from "@/services/cmsService";
import { EventBus } from '@/plugins/eventbus.js';
import tenants from '@/data/tenants.config'
import BigButton from '../components/BigButton.vue';
import SectionIntegrations from "@/components/SectionIntegrations.vue";
import SectionCustomers from "@/components/SectionCustomers.vue";
const tenant = tenants.current();
//import menuJson from `@/../public/static/cms/'${tenant.cmsMenuKey}'.json`;

export default {
  name: "CmsPage",
  components: {
    VueMarkdown,
    BigButton,
    SectionIntegrations,
    SectionCustomers,
  },
  props: {
  },
  data: function() {
    return {
      tenant: tenant,
      breadcrumbs: [],
      menuItem: null,
      page: null,
      currentPath: null,
      //title: null,
      //image: null,
    };
  },
  created() {
  },
  async mounted() {
    await this.loadContent(this.$route);
  },

  async beforeRouteEnter (to, from, next) {
    next(vm => {
      // access to component instance via `vm`
      console.log('beforeRouteEnter', to.path, vm);
      vm.loadContent(to);
    });
  },
  async beforeRouteUpdate (to, from, next) {
    // react to route changes...
    console.log('beforeRouteUpdate', to.path);
    this.page = null;
    await this.loadContent(to);
    next();
  },

  methods: {
    async loadContent(route) {
      if (route.path === this.currentPath) {
        console.log('[cms.page] skip load identical page');
        return;
      }
      console.log(`[cms.page - ${Date.now()}] start page load`, this.page);
      this.currentPath = route.path;
      this.page = null;
      this.menuItem = null;
      //console.log('menu - pre import');
      this.menu = this.menu || (await import(/* webpackMode: "eager" */ `@/data/cms/${tenant.cmsMenuKey}`)).default;
      //console.log('menu', this.menu, tenant.cmsMenuKey);
      //this.menu = import(/* webpackMode: "eager" */ `../../static/cms/${tenant.cmsMenuKey}.json`)
      //this.menu = await cmsService.getData('nav-menu-cofi');
      var menuItemPath = this.getPageStack(this.menu, route.path, []) || [];
      this.menuItem = menuItemPath.at(-1);
      this.breadcrumbs = menuItemPath.map(x => ({ text: x.title, to: x.path == this.menuItem && this.menuItem.path ? null : x.path, exact: true }));
      //console.log('route', route.path, menuItemPath, this.menuItem);

      if (this.menuItem && this.menuItem.related) {
        console.log(`[cms.page - ${Date.now()}] load from slug`, this.menuItem.related.slug);
        //this.page = menuItem.related;// await cmsService.getContent('pages', 'product-benefits-teams-and-groups');
        this.page = await cmsService.getItem('pages', this.menuItem.related.slug);
      }

      if (!this.menuItem && !this.page) {
        console.log(`[cms.page - ${Date.now()}] load from path`, route.path);
        this.page = await cmsService.getItemByPath('pages', route.path);
        if (this.page && (!this.breadcrumbs || !this.breadcrumbs.length)) {
          let pathOneUp = route.path.split('/').slice(0, -1).join('/');
          //console.log('route', route.path, 'up', pathOneUp);

          var menuItemPath = this.getPageStack(this.menu, pathOneUp, []) || [];
          this.menuItem = menuItemPath.at(-1);
          this.breadcrumbs = [
            ...menuItemPath.map(x => ({ text: x.title, to: x.path == this.menuItem && this.menuItem.path ? null : x.path, exact: true })),
            { text: this.page.title }
          ];

        }
      }
      //console.log('this.page', this.page, this.menuItem);

      if (this.page == null) {
        // todo: redirect to 404
      }
      else {
        console.log(`[cms.page - ${Date.now()}] page loaded`, this.page);
        //this.$route.meta.title = this.page.title;
        EventBus.$emit('page-header-change', { title: (this.menuItem && this.menuItem.title) || this.page.title, image: this.page.image, description: this.page.summary } );
      }
      this.$nextTick(() => {
        // when content is loaded
      });
    },
    getPageStack(menu, path, stack) {
      for (var item of menu) {
        if (item.path === path) {
          return [...stack, item];
        }
        if (item.items) {
          // recurse
          var subresult = this.getPageStack(item.items, path, [...stack, item]); 
          if (subresult != null) {
            return subresult;
          }
        }
      }
      return null;
    },
    getPagePath(slug) { return this._getPagePath(this.menu, slug); },
    _getPagePath(menu, slug) {
      for (var item of menu) {
        if (item.slug === slug || (item.related && item.related.slug === slug)) {
          return item.path;
        }
        if (item.items) {
          // recurse
          var subresult = this._getPagePath(item.items, slug); 
          if (subresult != null) {
            return subresult;
          }
        }
      }
      return null;
    },
    isAbsoluteUrl(path) { return path && path.startsWith('https://'); }
  },
  computed: {
    template() {
      return (this.page && this.page.template) || 'default';
    },
  },
  watch: {
  }
};
</script>

<style lang="scss" scoped>

  .hero-container {
    background-position: 50% 50% !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    min-height: 300px;
    margin-bottom: -100px !important;
  }

  .background-container { 
      background-position: 50% 0%;
      background-size: cover;
      padding-top: 200px;
      margin-top: 0;

      .v-sheet {
        background: rgba(255,255,255, 0.9) !important;
      }
    }
</style>